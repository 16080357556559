<template>
  <v-app>
    <v-app-bar app fill color="appbar" height="50">

      <v-img v-if="logoUrl != null" class="shrink mr-2" contain :src="logoUrl" transition="scale-transition" width="32" />

      <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">{{ title }} </v-toolbar-title>
      <span class="px-4">{{ status }}</span>
      <v-spacer></v-spacer>
      <v-btn v-if="markers[0].items.length > 0" class="px-2" outlined @click="markersDrawer = true">
        <v-icon small class="me-1">mdi-map-marker-outline</v-icon>
        {{ markers.title }}
      </v-btn>
      <v-btn v-if="paths[0].items.length > 0"  class="px-2 ms-3 me-n3" outlined @click="pathsDrawer = true">
        <v-icon small class="me-1">mdi-vector-polyline</v-icon>
        {{ paths.title }}
      </v-btn>
      <v-btn class="mx-1 me-n6" plain @click.stop="dialog = true">
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </v-app-bar>

    <v-dialog v-model="dialog" max-width=400>
      <v-card>
        <v-card-title class="text-h5">
          {{ infoTitle }}
        </v-card-title>
        <v-card-text>
          <span v-html="infoBody"></span>
        </v-card-text>
        <v-card-text>
          <v-spacer />
          <small><small>{{ infoCopyright }}</small></small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ infoButton }}
          </v-btn>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-navigation-drawer v-model="markersDrawer" touchless right absolute temporary width=300 height="80%">
      <v-list dense nav>
        <v-list-group v-for="item in markers" :key="item.title" sub-group :value="true" :append-icon="item.action">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"> </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="child in item.items" :key="child.title" min-width="300px"
            @click="goTo(item.id, child.coordinates, child.id)">
            <v-list-item-title v-text="child.title" />
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="pathsDrawer" touchless right absolute temporary width=300 height="80%">
      <v-list dense nav>
        <v-list-group v-for="item in paths" :key="item.title" sub-group :value="true" :append-icon="item.action">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"> </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="child in item.items" :key="child.title" min-width="300px">
            <v-switch :color="child.color" v-model="child.active" hide-details class="ms-n7 mt-0 me-4"></v-switch>
            
              <v-list-item-title v-text="child.title" @click="goTo(item.id, child.coordinates, child.id, child.active)" />
              <v-list-item-icon>
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-icon>
            
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <map-view @dataLoaded="mapDataLoaded" @status="showStatus" ref="mapView" />
      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import MapView from './components/MapView.vue'

export default {
  name: 'App',

  components: {
    MapView,
  },

  data: () => ({
    title: "", //process.env.VUE_APP_TITLE,
    logoUrl: null, //process.env.VUE_APP_LOGO_URL,
    infoTitle: "",
    infoBody: "",
    infoCopyright: "",
    infoButton: "OK",

    markersDrawer: false,
    pathsDrawer: false,

    dialog: false,
    group: null,
    status: '',
    overlay: true,

    markers: [
      {
        action: 'mdi-star',
        active: true,
        items: [],
        title: 'PAIKAT',
        id: 'places',
      },
    ],
    paths: [
      {
        action: 'mdi-vector-polyline',
        active: true,
        items: [],
        title: 'REITIT',
        id: 'paths',
      },
    ],

  }),

  methods: {
    mapDataLoaded (data) {

      if (data.settings[0].Title != undefined && data.settings[0].Title != "") {
        this.title = data.settings[0].Title
      }

      if (data.settings[0].LogoUrl != undefined && data.settings[0].LogoUrl != "") {
        this.logoUrl = data.settings[0].LogoUrl
      }

      if (data.settings[0].WebPageTitle != undefined && data.settings[0].WebPageTitle != "") {
        document.title = data.settings[0].WebPageTitle
      }

      this.infoTitle = data.settings[0].InfoTitle
      this.infoBody = data.settings[0].InfoBody
      this.infoCopyright = data.settings[0].InfoCopyright
      this.infoButton = data.settings[0].InfoButton

      this.markers.title = data.settings[0].MarkersTitle
      this.paths.title = data.settings[0].PathsTitle

      this.markers[0].items = data.markers

      // If no paths, remove entry from menu
      if (data.paths.length > 0) {
        this.paths[0].items = data.paths
      } else {
        this.paths[0].items = []
      }
      this.overlay = false
    },

    goTo (id, coordinates, pathId, active) {

      //this.pathsDrawer =  false
      this.markersDrawer = false

      if (id == 'places') {
        this.$refs.mapView.openPlace(pathId, coordinates)
        this.$nextTick(() => {
         
          setTimeout(() => {
          this.$refs.mapView.openPlace(pathId, coordinates)
        }, 700)
        })
      }

      if (id == 'paths') {
        this.$refs.mapView.goToPath(pathId)
        this.$refs.mapView.setPathVisibility(pathId, active)
      }
    },

    showStatus (data) {
      // If edit mode is enabled, status event will send click coordinates
      this.status = String(data)
    },
  },
  mounted () {
    this.$vuetify.theme.themes.light.primary = '#000000'
    this.$vuetify.theme.themes.light.appbar = '#f9f9f9'
  },
}
</script>
<style lang="scss">
// Fix screen for mobile
html,
body {
  overflow: hidden;
}
</style>
