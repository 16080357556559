<template>
  <l-map v-if="showMap" id="nodesMap" class="nodes-map" :options="mapOptions" :zoom="zoom" :maxZoom="maxZoom"
    :center="center" @click="getCoordinate" ref="myMap">
    <l-control-attribution position="bottomright" prefix="Leaflet"></l-control-attribution>
    <l-control-layers position="topright"></l-control-layers>
    <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
    <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.Name" :name="tileProvider.Name"
      :visible="tileProvider.Visible" :url="tileProvider.URL" :attribution="tileProvider.Attribution" layer-type="base" />
    <v-locatecontrol :options="locateOptions" />
   

    <l-geo-json v-for="item in paths" :key="item.title" :geojson="item.geoJson" :name="item.title"
      :options="{ color: item.color, weight: item.active ? item.weight : 0, id: item.id, dashArray: '6 6' }"
      @click="showInfo(item)">
    </l-geo-json>

    <l-geo-json v-if="showBorder" :geojson="borderGeoJson" name="border" :options="borderOptions"></l-geo-json>

    <v-marker-cluster v-if="showCluster" :options="clusterOptions">
      <l-marker v-for="marker in markers" :key="marker.id" :lat-lng="marker.coordinates"
        :ref="marker.id">
        <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" :popup-anchor="popupAnchor"
          :tooltip-anchor="tooltipAnchor" :shadow-size="shadowSize"
          shadowUrl="https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png"
          icon-url="https://raw.githubusercontent.com/jforsten/leaflet-color-markers/master/img/marker-icon-2x-red.png">
          <div v-if="marker.tag != undefined" class="item" :label="marker.tag"></div>
        </l-icon>
        <l-tooltip>{{ marker.title }}</l-tooltip>
        <l-popup :options="getPopupOptions(marker.id)">
          <v-container>
            <v-layout>
              <v-row>
                <v-col v-if="marker.imageUrls.length > 0" cols="12">
                  <v-carousel height="200" hide-delimiters show-arrows-on-hover class="elevation-7">
                    <v-carousel-item style="width:307px;height:auto;" v-for="(item, i) in marker.imageUrls" :key="i"
                      :src="item"></v-carousel-item>
                  </v-carousel>
                </v-col>
                <v-col wrap cols="12" class="my-0 py-0">
                  <div class="text-h5">
                    <v-icon>{{ marker.icon }}</v-icon> {{ marker.title }}
                  </div>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <p class="text-justify font-weight-bold">
                    {{ marker.body }}
                  </p>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <div v-for="(link, i) in marker.links" :key="i" class="text-justify font-weight-bold blue--text my-2"
                    @click="openLink(link.url)">
                    <v-icon small color="blue">mdi-open-in-new</v-icon>
                    <span class="text-decoration-underline">{{
                      link.name
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-layout>
          </v-container>
        </l-popup>
      </l-marker>
    </v-marker-cluster>
  </l-map>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlLayers,
  LControlScale,
  LControlAttribution,
  LGeoJson,
  LTooltip,
  LIcon,
} from 'vue2-leaflet'
import 'proj4leaflet'
import L from 'leaflet'

// Vue2 Leaflet Plugins
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import Vue2LeafletLocatecontrol from 'vue2-leaflet-locatecontrol'

import PublicGoogleSheetsParser from 'public-google-sheets-parser'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlLayers,
    LControlScale,
    LControlAttribution,
    LGeoJson,
    LTooltip,
    LIcon,
    // Plugins
    'v-locatecontrol': Vue2LeafletLocatecontrol,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  data () {
    return {
      sheetId: process.env.VUE_APP_GOOGLE_SHEET_ID,
      enableExternalSheets: process.env.VUE_APP_ENABLE_GOOGLE_SHEET_AS_A_QUERY_PARAMETER,
      maxZoom: 16,
      zoom: 5,
      center: [62.918093896244095, 28.11383870595909],

      globalSettings: [],
      tileProviders: [],
      markers: [],
      paths: [],

      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [13, -20],
      shadowSize: [41, 41],

      mapOptions: {
        preferCanvas: true,
        attributionControl: false
      },

      clusterOptions: {},

      locateOptions: {
        flyTo: true,
        keepCurrentZoomLevel: true,
      },
      popupOptions: {
        maxWidth: 330,
        keepInView: true,
        autoPanPadding: L.point(2, 150),
        id: ""
      },
      editMode: false,

      showBorder: false,
      borderGeoJson: null,
      borderOptions: { color: 'blue', weight: 3, id: 1 },
      showCluster: false,
      showMap: false
    }
  },
  computed: {
    parser () {
      return new PublicGoogleSheetsParser()
    },
    dynamicSize () {
      return this.iconSize
    },
    dynamicAnchor () {
      return this.iconAnchor
    },
  },
  methods: {

    getPopupOptions (id) {
      return {
        maxWidth: 330,
        keepInView: true,
        autoPanPadding: L.point(2, 150),
        id: id
      }
    },

    async getTileProviders () {
      const data = await this.parser.parse(this.sheetId, 'Maps')
      this.tileProviders = data.map((i) => {
        return {
          Name: i.Name,
          Attribution: i.Attribution,
          Visible: i.Enabled === undefined ? false : true,
          URL: i.URL,
        }
      })
    },

    async getSettings () {
      let settings = await this.parser.parse(this.sheetId, 'Settings')
      this.center = this.parseLatLng(settings[0].Center)
      this.zoom = settings[0].Zoom
      this.maxZoom = settings[0].MaxZoom
      this.clusterOptions.disableClusteringAtZoom = settings[0].ClusteringZoom
      this.markersTitle = settings[0].MarkersTitle
      this.pathsTitle = settings[0].PathsTitle
      this.globalSettings = settings
      this.showMap = true
      this.showCluster = true
    },

    async getMarkers () {

      let markers = await this.parser.parse(this.sheetId, 'Markers')

      let parse = this.parseLatLng

      let id = 0

      this.markers = markers.map((item) => {
        var links = []
        let linkNames =
          item.LinkNames == undefined ? [] : item.LinkNames.split('\n')
        let linkUrls =
          item.LinkURLs == undefined ? [] : item.LinkURLs.split('\n')

        if (linkNames.length == linkUrls.length && linkNames.length > 0) {
          links = linkNames.map((item, index) => {
            return { name: item, url: linkUrls[index] }
          })
        }

        let imageUrls =
          item.ImageURLs == undefined ? [] : item.ImageURLs.split('\n')

        return {
          id: 'marker' + id++,
          title: item.Title,
          icon: item.Icon,
          tag: item.Tag,
          body: item.Body,
          imageUrls: imageUrls,
          links: links,
          coordinates: parse(item.Coordinates),
        }
      })
    },

    async getPaths () {
      let idx = 0
      let paths = await this.parser.parse(this.sheetId, 'Paths')
      this.paths = paths.map((item) => {
        return {
          title: item.Title,
          icon: item.Icon,
          color: item.Color,
          weight: item.Weight,
          id: idx++,
          active: false,
          geoJson: JSON.parse(item.GeoJson),
        }
      })
    },

    parseLatLng (coordinateString) {
      var values = coordinateString.split(',')
      var v1 = parseFloat(values[0])
      var v2 = parseFloat(values[1])
      return [v1, v2]
    },

    goToPlace (coordinates) {
      this.$refs.myMap.mapObject.flyTo(coordinates, 13)
    },

    openPlace (id, coordinates) {
      let map = this.$refs.myMap.mapObject
      map.setView(coordinates, 13)

      map.eachLayer((layer) => {

        if (layer._popup) {
          if (layer._popup.options.id == id) {
            layer.openPopup(null)
            return
          }
        }
      })

    },


    goToPath (id) {
      let map = this.$refs.myMap.mapObject
      this.$refs.myMap.mapObject.eachLayer(function (layer) {
        if (layer.options) {
          if (layer.options.id == id) {
            map.flyToBounds(layer.getBounds())
          }
        }
      })
    },

    setPathVisibility (id, enable) {
      this.$set(this.paths[id], 'active', enable)
    },

    openLink (link) {
      // Open in new tab
      window.open(link, '_blank').focus()
    },

    getCoordinate (data) {
      // If on edit mode, send coordinates to parent and save also to clipboard..
      if (this.editMode) {
        let coordinate = data.latlng.lat + ', ' + data.latlng.lng
        navigator.clipboard.writeText(coordinate)
        this.$emit('status', coordinate)
      }
    },

    async mapSetup () {

      // Use query parameter "edit=true" to enable edit mode...
      const urlParams = new URLSearchParams(window.location.search)


      if (this.enableExternalSheets == 'true' && urlParams.get('map_data_id') != undefined) {
        this.sheetId = urlParams.get('map_data_id')
      }
      this.editMode = urlParams.get('edit') == 'true'
      // TODO: Get from sheet
      this.showBorder = urlParams.get('border') == 'true'

      // TODO: Add parameter to get coordinates or title (marker=...). If matched with markers, got to marker and open popup
      // (for open popup: https://stackoverflow.com/questions/60917056/vue-leaflet-open-popup-when-loaded)

      // TODO: Add parameter to zoom to path If matched with title
      await this.getSettings()
      await this.getTileProviders()
      await this.getMarkers()
      await this.getPaths()

      this.$emit('dataLoaded', {
        settings: this.globalSettings,
        markers: this.markers,
        paths: this.paths,
      })
    }
  },

  async created () {
    // TODO: Load from sheet
    const response = await fetch('./riistavesi.geojson')
    this.borderGeoJson = await response.json()
  },

  async mounted () {
    this.mapSetup()
  },

}
</script>

<style lang="scss">
@import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import 'https://cdn.jsdelivr.net/npm/leaflet.locatecontrol@0.74.0/dist/L.Control.Locate.min.css';

//@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

// Marker Cluster
@import '~leaflet.markercluster/dist/MarkerCluster.css';

.marker-cluster-small {
  background-color: rgba(129, 101, 101, 0.678);
}

.marker-cluster-small div {
  background-color: rgb(0, 0, 0);
}

.marker-cluster-medium {
  background-color: rgba(131, 114, 47, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(51, 44, 16, 0.6);
}

.marker-cluster-large {
  background-color: rgba(78, 48, 35, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(53, 36, 21, 0.6);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px bold 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: rgb(255, 255, 255);
}

.marker-cluster span {
  line-height: 30px;
}

// Map view fixes...
.nodes-map {
  width: 100%;
  height: 100%;
  z-index: 0;
}

// Custom marker with label

:root {
  --label: '1';
  --bg: #fdfdfd;
  --highlight1: #cc0000;
  --highlight2: #cc0000;
  --color: #1a1e24;
  --font-number: Montserrat, Roboto, Helvetica, Arial, sans-serif;
  --font-head: 'Space Mono', Consolas, Menlo, Monaco, 'Courier New', monospace;
  --font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.item {
  display: block;
  clear: both;
  padding-bottom: 4rem;
  font-size: 1.1rem;
  line-height: 1.375;
  position: relative;
  top: 0rem;
  left: -0.2rem;
}

.item:before {
  font: bold 1rem/1 var(--font-number);
  content: attr(label);
  width: 2rem;
  height: 2rem;
  float: left;
  margin: 0 1.5rem 0.75rem 0;
  color: var(--bg);
  background: var(--highlight1) linear-gradient(to bottom right, var(--highlight1) 25%, var(--highlight2));
  text-shadow: 0 0 2px var(--highlight1);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  shape-outside: ellipse();
  z-index: 1;
}

.item:after {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.25rem;
  left: 0.5rem;
  content: '';
  background: var(--highlight1);
  z-index: -1;
  border-top-left-radius: 3px;
  transform: rotate(45deg);
}
</style>
